import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { getSafe } from '../utils/getSafe';
import Loading from '../components/Loading/Loading';
import VideoPage from '../components/VideoPage/VideoPage';

const Videos = ({ data }) => {
    return (
        <Layout className="videos-page">
            <Loading />
            <VideoPage />
        </Layout>
    );
};

export default Videos;
