import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import SEO from '../SEO/SEO';
import NavBar from '../NavBar/NavBar';
import Title from '../Title/Title';
import Hero from '../Hero/Hero';
import VideoThumbnail from '../VideoThumbnail/VideoThumbnail';
import ScrollRevealAnimation from '../ScrollRevealAnimation/ScrollRevealAnimation';
import { Pagination } from '../../utils/pagination';
import { getSafe } from '../../utils/getSafe';
import { fetchPrismicResourcesVideos, findGetParameter } from '../../utils/prismic';
import Select from 'react-select';
import { SelectOptions, SelectStyles, DropdownIndicator } from '../../utils/orderBy';
import '../../stylesheets/components/ordering.scss';

const VideoPage = ({ blocked }) => {
    /* Don't allow to see the page if the user is blocked */
    if (blocked) {
        navigate('/');
        return null;
    }
    const [videos, setVideos] = useState([]);
    const [pageInfo, setPageInfo] = useState({ pageCount: 1 });
    const [currentPage, setCurrentPage] = useState(findGetParameter('p'));
    const [selectValue, setSelectValue] = useState({ value: 'desc', label: 'NEWEST > OLDEST' });
    const [displayPagination, setDisplayPagination] = useState(true);

    useEffect(() => {
        let isMounted = true;
        getPrismicResourcesVideos(isMounted, currentPage, selectValue.value);
        return () => {
            isMounted = false;
        };
    }, [currentPage]);

    const getPrismicResourcesVideos = (isMounted, currentPage, ordering = 'desc') => {
        setDisplayPagination(false);
        setVideos([]);
        fetchPrismicResourcesVideos(currentPage, ordering).then((res) => {
            if (res && res.data && res.data.length && isMounted) {
                setPageInfo({ pageCount: res.total_pages });
                setVideos(res.data);
                setDisplayPagination(true);
            }
        });
    };

    const handlerSelect = (event) => {
        setCurrentPage(1);
        getPrismicResourcesVideos(true, 1, event.value);
    };
    const title = { text: 'Videos' };
    return (
        <>
            <SEO title={title} type="WebPage" urlPath="/videos" />
            <Hero
                leftImage="garfield45/home-garfield-cake.png"
                rightImage="garfield45/home-garfield-45th-mobile.png"
                rightImageMobile="garfield45/home-garfield-45th-mobile.png"
                classNameExternal="hero-videos"
            />
            <NavBar active="videos" />
            <Title fileName="videos-title.png" className="page-title" alt="Videos Title" />
            <div className="container video-ordering">
                <label>Order by</label>
                <Select
                    options={SelectOptions()}
                    value={selectValue}
                    onChange={(value) => {
                        setSelectValue(value);
                        handlerSelect(value);
                    }}
                    components={{ DropdownIndicator }}
                    className="ordering-select"
                    classNamePrefix="react-select"
                    styles={SelectStyles()}
                    isSearchable={false}
                />
            </div>
            <div className="container videos-container">
                <div className="columns is-flex-wrap-wrap">
                    {videos &&
                        videos.map((video, i) => {
                            const data = getSafe(() => video.data, {});
                            const description = getSafe(() => data.description[0].text, null);
                            const title = getSafe(() => data.title[0].text, null);
                            let url = getSafe(() => video.uid, null);
                            if (url) {
                                url = '/videos/' + url;
                            }
                            const image = data.image.url;
                            return (
                                <div className="column is-12-mobile is-6-tablet is-4-desktop" key={i}>
                                    <ScrollRevealAnimation
                                        animation="fadeIn"
                                        className=""
                                        delay={i * 0.25}
                                        offset="50px"
                                    >
                                        <VideoThumbnail
                                            key={i}
                                            title={title}
                                            thumbnail={image}
                                            description={description}
                                            url={url}
                                        />
                                    </ScrollRevealAnimation>
                                </div>
                            );
                        })}
                </div>
            </div>
            <Pagination
                pageInfo={pageInfo}
                slug="videos"
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                displayPagination={displayPagination}
            />
        </>
    );
};

VideoPage.propTypes = {
    blocked: PropTypes.bool,
};

const mapStateToProps = ({ blocked }) => {
    return { blocked };
};

export default connect(mapStateToProps, null)(VideoPage);
